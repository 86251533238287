import React from 'react'
import styled from '@emotion/styled'
import { useState } from 'react'
import mq from '../styles/mediaQueries'
import { Link } from 'gatsby'

import NavBar from '../components/NavBar'
import NavOverlay from '../components/NavOverlay'
import Layout from '../components/Layout'
import { Body1, Headline1 } from '../components/Typography'

import ThumbsDownSVG from '../assets/icons/thumbs-down.svg'

const Wrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '3', '3'],
    gridColumnEnd: ['5', '9', '12', '11', '11'],
  })};
  ${props => (props.isOverlayOpen ? 'hidden' : 'visible')};
`

const BackgroundWrapper = styled.div`
  background: linear-gradient(90.9deg, #2e60ff 0%, rgba(46, 96, 255, 0) 99.36%),
    #0000fe;
  height: 100vh;
  display: flex;
  align-items: center;
`

const ThumbsDown = styled(ThumbsDownSVG)`
  position: absolute;
  ${mq({
    width: ['60px', '60px', '122px', '122px', '122px'],
    height: ['60px', '60px', '122px', '122px', '122px'],
    margin: [
      '-30px 0 0 -15px',
      '-30px 0 0 -30px',
      '-70px 0 0 -70px',
      '-70px 0 0 -70px',
      '-70px 0 0 -70px',
    ],
  })};
`

const NotFoundTitle = styled.div`
  ${Headline1}
  color: var(--terminal-0);
  margin-bottom: 16px;
`

const NotFoundLink = styled(Link)`
  ${Body1}
  color: var(--terminal-0);
  font-weight: 600;
  text-decoration: none;
`

function NotFound(props) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [pixels, setPixels] = useState(0)
  return (
    <React.Fragment>
      <NavBar
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        pixels={pixels}
        setPixels={setPixels}
        theme={'blue'}
      />
      <NavOverlay
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <BackgroundWrapper>
        <Layout>
          <Wrapper>
            <ThumbsDown />
            <NotFoundTitle>Page not found.</NotFoundTitle>
            <NotFoundLink to="/">← Back to the home page</NotFoundLink>
          </Wrapper>
        </Layout>
      </BackgroundWrapper>
    </React.Fragment>
  )
}

export default NotFound
